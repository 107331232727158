#block-search, #block-search * {
    box-sizing: border-box;
}

#block-search {
    width: 90%;
    max-width: 1200px;
    margin: 30px auto;
    background: rgba(255, 255, 255, 0.71);
    border: 1px solid #828282;
    border-radius: 5px;
}

@media screen and (min-width: 1300px) and (min-height: 800px) {
    #block-search.--main {
        position: relative;
        z-index: 1;
        margin-top: -210px;
        margin-bottom: 115px;
    }

    .outlet-home .hero-container {
        padding-bottom: 100px;
    }
}

.be-container {
    padding: 0 30px;
}

.booking-form-wrapper {
    padding: 50px 20px;
}

#be-booking-form {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    z-index: 1;
}

section.nav a.booking-btn {
    color: #ffffff;
}

section.nav a.booking-btn:hover {
    color: rgb(4, 4, 4);
}
